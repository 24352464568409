<template>
  <div class="course-certificate">
    <div class="q-mb-md">
      <q-item-section>
        <q-item-label v-for="(item, i) in [1, 2]" :key="i">
          <q-skeleton type="rect" animation="fade" />
        </q-item-label>
      </q-item-section>

      <q-item class="q-pr-none q-pl-none ">
        <q-item-section avatar>
          <q-skeleton type="QAvatar" animation="fade" />
        </q-item-section>
        <q-item-section>
          <q-item-label v-for="(item, i) in [1, 2]" :key="i">
            <q-skeleton type="rect" animation="fade" />
          </q-item-label>
        </q-item-section>
      </q-item>
      <q-btn
        disabled
        color="dark"
        icon="download"
        class="full-width q-mt-md"
        :label="$t('course.download_certificate')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseItemSelect",
};
</script>

<style lang="scss">
.course-certificate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .q-rating__icon {
    text-shadow: none !important;
  }
  .title {
    text-align: left;
  }
  .win-points {
    color: #ff5768;
    text-align: center;
    i {
      margin-right: 10px;
    }
  }
  .img-content {
    width: 100%;
    overflow: hidden;
  }
  .description {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }
  .code {
    margin-top: 10px;
    font-size: 10px;
  }
}
.mobile {
  .course {
    padding: 15px 10px;
    .title {
      font-size: 0.9em;
      font-weight: 500;
    }
    .win-points {
      font-size: 0.7em;
    }
    .description {
      font-size: 9px;
    }
  }
}
</style>
