<template>
  <page-title
    class="q-mb-lg"
    title-class
    :skeleton="false"
    :title="$t('course.certificate')"
  />
  <div class="row" v-if="certificate">
    <div class="col-xs-12 col-sm-8">
      <div class="default-card-border overflow-hidden">
        <q-img
          style="border: 1px solid rgb(189 189 189)"
          :src="certificate.certificate_Url.jpg"
        />
      </div>
    </div>
    <div class="col-xs-12 col-sm-4">
      <div
        class="q-mt-none self-baseline default-rounded bg-white q-pa-md "
        :class="{
          'q-mt-lg': $q.screen.width <= 800,
          'q-ml-lg': $q.screen.width > 800,
        }"
      >
        <course-item
          class="c-course-item"
          :course="certificate.Data"
          :pdf="certificate.certificate_Url.pdf"
        />
      </div>
    </div>
  </div>

  <div class="row" v-else>
    <div class="col-xs-12 col-sm-8">
      <div class="default-card-border overflow-hidden text-center">
        <q-skeleton height="600px" square />
      </div>
    </div>
    <div class="col-xs-12 col-sm-4">
      <div
        class="q-mt-none self-baseline default-rounded bg-white q-pa-md "
        :class="{
          'q-mt-lg': $q.screen.width <= 800,
          'q-ml-lg': $q.screen.width > 800,
        }"
      >
        <course-item-skeleton />
      </div>
    </div>
  </div>
</template>

<script>
import CourseItemSkeleton from "@/modules/main/pages/course/components/CourseItemSkeleton";
import CourseItem from "@/modules/main/pages/course/components/CourseItem";
import PageTitle from "../../components/page-title";

import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute } from "vue-router";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("course");

export default {
  name: "certificate",

  components: { PageTitle, CourseItem, CourseItemSkeleton },

  setup() {
    const route = useRoute(),
      certificate = ref(null),
      { fetchCertificate } = useActions(["fetchCertificate"]);

    const initCourse = () => {
      fetchCertificate({
        slug: route.params.slug,
      }).then((data) => (certificate.value = data));
    };

    initCourse();

    return {
      certificate,
    };
  },
};
</script>

<style lang="scss">
.c-course-item {
  .q-img {
    max-width: 185px;
  }
  p {
    font-size: 1.5em !important;
  }
}
.mobile {
  .c-course-item {
    .q-img {
      width: 100px;
    }
  }
}
</style>
