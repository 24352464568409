<template>
  <div class="course-certificate">
    <div class="q-mt-xs q-mb-md">
      <h6 class="q-ma-none full-width text-weight-light" v-html="'Curso:'" />
      <p
        v-html="course.courseName"
        class="text-weight-bold text-left full-width self-center text-justify q-mt-none q-mb-none q-ml-sm"
      />
    </div>

    <div class="q-mb-md">
      <h6
        class="q-ma-none q-mb-sm text-weight-light"
        v-html="'Destinatário do certificado:'"
      />
      <div class="row no-wrap">
        <q-avatar>
          <img :src="course.photo_url" />
        </q-avatar>

        <div class="q-ml-sm">
          <p
            class="text-weight-bold self-center q-mt-none q-mb-none"
            :style="{ fontSize: '1.3em' }"
            v-html="course.nome"
          />
          <div
            class="text-weight-bold text-grey-8"
            v-html="$t('course.finished_at', { value: course.finished_at })"
          />
        </div>
      </div>
    </div>

    <q-btn
      color="dark"
      icon="download"
      class="full-width"
      :label="$t('course.download_certificate')"
      @click="download"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { isJson } from "@/shared/helpers/stringManipulations";
import { ref, computed } from "vue";

const UseAssents = createNamespacedHelpers("assents");

export default {
  name: "CourseCertificate",

  props: {
    course: {
      type: Object,
      required: true,
    },
    pdf: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const pointToEnroll = ref(parseInt(props.course.points_to_enroll)),
      imageFetchFailed = ref(false),
      { ImgNotFound } = UseAssents.useGetters(["ImgNotFound"]);

    const imageThumb = computed(() => {
      if (imageFetchFailed.value) {
        return ImgNotFound;
      } else if (isJson(props.course.logo_foto_link)) {
        return JSON.parse(props.course.logo_foto_link).capa_medium;
      } else {
        return props.course.logo_foto_link;
      }
    });

    const downloadURI = (dataUrl, filename) => {
        var link = document.createElement("a");
        link.download = filename;
        link.target = "_blank";

        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
      },
      download = () => {
        downloadURI(
          props.pdf,
          "certificado_" +
            props.course.nome.toLowerCase().replaceAll(" ", "_") +
            ".pdf"
        );
      };

    return {
      pointToEnroll,
      imageThumb,
      imageFetchFailed,
      download,
    };
  },
};
</script>

<style lang="scss">
.course-certificate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .q-rating__icon {
    text-shadow: none !important;
  }
  .title {
    text-align: left;
  }
  .win-points {
    color: #ff5768;
    text-align: center;
    i {
      margin-right: 10px;
    }
  }
  .img-content {
    width: 100%;
    overflow: hidden;
  }
  .description {
    margin-top: 10px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
  }
  .code {
    margin-top: 10px;
    font-size: 10px;
  }
}
.mobile {
  .course {
    padding: 15px 10px;
    .title {
      font-size: 0.9em;
      font-weight: 500;
    }
    .win-points {
      font-size: 0.7em;
    }
    .description {
      font-size: 9px;
    }
  }
}
</style>
